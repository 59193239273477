import React from 'react';
import FilterJobs from "../../components/Home/FilterJobs"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerPage from "../../components/Common/BannerPage"

const JobList = ({location, uri, history}) => (
  <>
    <BannerPage />
    <Layout page="page">
      <SEO title="Search Jobs" />  
      <div style={{ maxWidth: `1260px`, position:"relative", color:"#fff", marginBottom: `1.45rem` }}>
          <FilterJobs uri={uri} history={history} location={location} />
      </div>
    </Layout>
  </>
)

export default JobList


